import axios from 'axios';
import store from '@/store.js';
import router from '@/router/router';

export default {
  install(Vue) {
    let url_full = 'https://app.serviceart.ru/backEnd/';
    Vue.prototype.$sendRequest = function (url, param) {
      let fd = new FormData();
      for (const item in param) {
        fd.append(item, param[item]);
      }

      return new Promise(function (resolve, reject) {
        axios.post(url_full + url,
          fd, {
          headers: {
            auth: `${localStorage.getItem('key') ? localStorage.getItem('key') : null}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
        ).then((response) => {
          if (response) {
            if (response.data.auth === false) {
              localStorage.removeItem('key');
              store.commit('CHANGE_USER', null);
              if (router.history.path !== '/auth')
                router.push('/auth');
              return;
            }
            resolve(response.data ? response.data : null);
          }
        })
          .catch(function (error) {
            reject({ status: 1, errorText: error });
          });
      });
    };
  },
  sendRequest(url, param) {
    let url_full = 'https://app.serviceart.ru/backEnd/';
    let fd = new FormData();
    for (const item in param) {
      fd.append(item, param[item]);
    }

    return new Promise(function (resolve, reject) {
      axios.post(url_full + url,
        fd, {
        headers: {
          auth: `${localStorage.getItem('key') ? localStorage.getItem('key') : null}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
      ).then((response) => {
        if (response) {
          if (response.data.auth === false) {
            localStorage.removeItem('key');
            store.commit('CHANGE_USER', null);
            router.push('/auth');
            return;
          }
          resolve(response.data ? response.data : null);
        }
      })
        .catch(function (error) {
          console.error(error);
          reject({ status: 1, errorText: error });
        });
    });
  }
};