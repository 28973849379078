<template>
  <div class="finance__table__wrapper">
    <transition name="add_client">
      <modal
        v-if="modal_show"
        :type="modal_type"
        @close_modal="
          modal_type = null;
          modal_show = false;
        "
        @close_modal_refresh="func_update_table"
      />
    </transition>
    <div
      class="close__finance tooltip"
      @click="$router.push('/')"
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>

    <div class="finance__content">
      <div class="finance__body">
        <div class="header__finance__title">
          <h4>График оборота</h4>
        </div>
        <div class="graph__content">
          <div class="graph_round">
            
            <ul class="amount__li">
              <li>
                <div class="icon__money">
                  <i class="fa fa-money" aria-hidden="true"></i> В кассе
                </div>
                <div class="money__summ">{{ USER_STATE.amount_nal }}</div>
              </li>

          <!--    <li>
                <div class="icon__money">
                  <i class="fa fa-credit-card-alt" aria-hidden="true"></i> Карта
                </div>
                <div class="money__summ">{{ USER_STATE.amount_card }}</div>
              </li> -->
            </ul>
          </div>
          
        </div>
        <div class="finance__buttons">
          <button
            @click="
              modal_type = 1;
              modal_show = true;
            "
          >
            Добавить приход
          </button>
          <button
            @click="
              modal_type = 2;
              modal_show = true;
            "
          >
            Добавить расход
          </button>
        </div>
        <div class="finance__datepicker">
          <div class="datepicker__finance">
            <label>Фильтр по дате</label>
            <div class="datepicker_flex">
              <date-range-picker
                v-model="dateRange"
                :locale-data="param"
                opens="right"
                :ranges="false"
                :singleDatePicker="false"
                :auto-apply="true"
              >
              </date-range-picker>
              <button @click="func_print()" class="print__btn">
                <i class="fa fa-print"></i>
              </button>
            </div>
          </div>
        </div>
        <financeDatetime
          :print_table="print_table"
          :date_obj="dateRange"
          :update_table="update_table"
        />
      </div>
    </div>
  </div>
</template>
<script>
import financeDatetime from "@/components/Finance/finance_datatable.vue";
import modal from "@/components/Finance/modal.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { mapGetters } from "vuex";
export default {
  components: {
    financeDatetime,
    modal,
    DateRangePicker,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - 29);
    return {
      dateRange: { startDate, endDate },
      param: {
        direction: "ltr",
        format: "dd.mm.yyyy",
        separator: " - ",
        applyLabel: "Сохранить",
        cancelLabel: "Отменить",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        firstDay: 1,
      },
      modal_type: null,
      modal_show: false,
      update_table: false,
      print_table: false,
      menu: false,
      chartOptions: {
        background: "#000",
        labels: ["В работе", "Завершен", "Неудачный"],
        colors: ["#ff7575", "#29e5a5", "#838589"],
        fill: {
          type: "gradient",
          colors: ["#ff7575", "#29e5a5", "#838589"],
        },
        stroke: {
          colors: ["#000"],
        },
      },

      series: [0, 0, 0],
      series2: [
        {
          name: "Сумма",
          data: [],
        },
      ],
      options: {
        chart: {
          width: "100%",
          height: "100px",
          type: "line",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  methods: {
    func_print() {
      this.print_table = true;
      setTimeout(() => {
        this.print_table = false;
      }, 300);
    },
    func_get_graph1() {
      this.$sendRequest("order.php/get_graph", {
        date_start: new Date(this.dateRange.startDate).toISOString().slice(0, 10),
        date_end: new Date(this.dateRange.endDate).toISOString().slice(0, 10),
      }).then((result) => {
        if (!result.graph1) this.series = [0, 0, 0];
        let i1 = 0,
          i2 = 0,
          i3 = 0;
        result.graph1.forEach((element) => {
          if (element.status === "Завершен") {
            i2 = Number(element.count || 0);
          } else if (element.status === "Завершен неудачно") {
            i3 = Number(element.count || 0);
          } else {
            i1 += Number(element.count || 0);
          }
        });
        this.series = [i1, i2, i3];

        if (result.graph2) {
          let graph2_data = [];
          let categories = [];
          result.graph2.forEach(function (item) {
            graph2_data.push(Number(item.summ));
            let date = item.date.split("-");
            categories.push(date[2] + "." + date[1]);
          });

          this.$refs.realtimeChart.updateSeries(
            [
              {
                data: graph2_data,
              },
            ],
            false,
            true
          );
          this.$refs.realtimeChart.updateOptions({
            xaxis: {
              categories: categories,
            },
          });
        }
      });
    },
    func_update_table() {
      this.modal_show = false;
      this.modal_type = null;
      this.update_table = true;

      setTimeout(() => {
        this.update_table = false;
      }, 200);
    },
  },
  watch: {
    dateRange() {
      this.func_get_graph1();
    },
  },
  mounted() {
    this.func_get_graph1();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style>
.add_client-enter {
  transform: translateY(-100%) scale(0);
}

.add_client-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.add_client-enter-to {
  transform: translateY(0) scale(1);
}
.add_client-leave {
  transform: translateY(0) scale(1);
}
.add_client-leave-active {
  transition: 0.3s;
}

.add_client-leave-to {
  transform: translateY(-150%) scale(0);
}

.customer_detail-enter {
  transform: scale(0);
}

.customer_detail-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.customer_detail-enter-to {
  transform: scale(1);
}
.customer_detail-leave {
  transform: scale(1);
}
.customer_detail-leave-active {
  transition: 0.3s;
}

.customer_detail-leave-to {
  transform: scale(0);
}
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.reportrange-text {
  background: #333 !important;
  border: 1px solid transparent !important;
  border-radius: 5px;
  min-width: 200px;
  text-align: left;
}
.daterangepicker.openscenter {
  background: #333 !important;
}
.daterangepicker .calendar-table {
  background-color: #333 !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  background-color: #333 !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  /* border: solid #fff; */
}
.daterangepicker td.in-range {
  background-color: #58909f !important;
}
.daterangepicker .calendars-container {
  background-color: #222 !important;
}
</style>
