<template>
  <div class="data__table__clear" v-if="!progress && card_list.length === 0">
    <img :src="require('@/assets/catschool.png')" alt="" />
    <p>Краткое видео 5 минут научит всем ньюансов работы в системе</p>
    <a target="_blank" href="http://www.srmple.ru/training">Смотреть</a>
  </div>
</template>

<script>
export default {
  props: {
    progress: Boolean,
    card_list: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
