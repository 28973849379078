<template>
  <div class="header__comment__wrapper">
    <div
      class="header__comment"
      v-if="
        USER_STATE &&
        USER_STATE.count_day > 3 &&
        USER_STATE.title &&
        USER_STATE.title !== ''
      "
    >
      {{ USER_STATE.title }}
    </div>

    <div
      class="header__comment header__comment_red"
      v-if="USER_STATE && USER_STATE.count_day > 1 && USER_STATE.count_day <= 3"
    >
      {{ "На балансе осталось всего на " + USER_STATE.count_day + " дня." }}
      <span @click="func_show_plus_balance">Пополнить баланс</span>
    </div>
    <div
      class="header__comment header__comment_red"
      v-if="USER_STATE && USER_STATE.count_day === 1"
    >
      {{ "Завтра доступ к аккаунту будет заморожен." }}
      <span @click="func_show_plus_balance">Пополнить баланс</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
  methods: {
    func_show_plus_balance() {
      this.$router.push("/setting");
    },
  },
};
</script>

<style></style>
