<template>
  <div class="main__wrapper">
    <div class="main__content">
      <div class="main__header">
        <div class="header__left">
          <div class="box-cent">
            <div
              @click="func_drop_message()"
              class="tooltip"
              tooltip="Уведомления"
              tooltip-position="bottom right"
            >
              <div class="prices-cir">
                <div class="mes-r">
                  <img :src="require('@/assets/r.svg')" width="36px" />
                </div>
                <div class="arrow-7" v-if="MESSAGE_NO_SHOW_STATE">
                  <div class="ms1"></div>
                  <div class="ms2"></div>
                  <div class="ms3"></div>
                </div>
              </div>
            </div>
            <transition name="message_drop">
              <div
                class="drop__message"
                v-if="show_drop && MESSAGE_LIST_STATE.length > 0"
              >
                <div class="drop__message_trangle"></div>
                <div class="message__body">
                  <div
                    class="message__item"
                    v-for="item in MESSAGE_LIST_STATE"
                    :key="item.message_id"
                    @mouseover="func_show_message(item)"
                  >
                    <div class="message__title">
                      <div>
                        {{
                          new Date(
                            iosDate(item.create_date)
                          ).toLocaleDateString() +
                          " " +
                          new Date(
                            iosDate(item.create_date)
                          ).toLocaleTimeString()
                        }}
                      </div>
                      <div class="arrow-8" v-if="!!!Number(item.showing)">
                        <div class="ms11"></div>
                        <div class="ms22"></div>
                        <div class="ms33"></div>
                      </div>
                    </div>
                    <div class="message__content">
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="setting__radio tooltip"
            tooltip="Отображение списка"
            tooltip-position="bottom right"
          >
            <div class="radio__item">
              <div class="button-cover2">
                <div class="buttonst b2" id="button-14">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="user_row"
                    @change="func_save_row"
                  />
                  <div class="knobs">
                    <span></span>
                  </div>
                  <div class="layer tooltip"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__center">
          <button
            @click="$router.push('/clients')"
            class="tooltip"
            tooltip="Клиенты"
            tooltip-position="left"
          >
            <i class="fa fa-users" aria-hidden="true"></i>
          </button>
          
          <button
            @click="show_filter = !show_filter"
            class="tooltip"
            tooltip="Фильтр"
            tooltip-position="bottom right"
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt"
              height="512.000000pt"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                stroke="none"
              >
                <path
                  d="M1525 4311 c-125 -32 -221 -137 -240 -264 l-7 -46 -348 -3 c-334 -3
-350 -4 -376 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 26 -20 42
-21 376 -24 l348 -3 7 -47 c8 -59 59 -155 98 -188 16 -14 53 -37 81 -52 l51
-27 245 0 245 0 63 34 c35 19 74 48 88 64 38 45 71 117 79 170 l7 47 1148 2
c1142 3 1149 3 1176 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -34 21
-1176 24 l-1148 2 -7 47 c-8 53 -41 125 -79 170 -14 16 -53 45 -88 64 l-63 34
-225 2 c-124 1 -238 -2 -255 -6z m395 -471 l0 -160 -160 0 -160 0 0 160 0 160
160 0 160 0 0 -160z"
                />
                <path
                  d="M2805 3031 c-125 -32 -221 -137 -240 -264 l-7 -47 -988 -2 c-981 -3
-989 -3 -1016 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 27 -21 35
-21 1016 -24 l988 -2 7 -48 c8 -59 59 -155 98 -188 16 -14 53 -37 81 -52 l51
-27 245 0 245 0 63 34 c35 19 74 48 88 64 38 45 71 117 79 170 l7 46 508 3
c496 3 509 4 536 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 20 -40 21
-536 24 l-508 3 -7 46 c-8 53 -41 125 -79 170 -14 16 -53 45 -88 64 l-63 34
-225 2 c-124 1 -238 -2 -255 -6z m395 -471 l0 -160 -160 0 -160 0 0 160 0 160
160 0 160 0 0 -160z"
                />
                <path
                  d="M1525 1751 c-125 -32 -221 -137 -240 -264 l-7 -46 -348 -3 c-334 -3
-350 -4 -376 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 26 -20 42
-21 376 -24 l348 -3 7 -47 c8 -59 59 -155 98 -188 16 -14 53 -37 81 -52 l51
-27 245 0 245 0 63 34 c35 19 74 48 88 64 38 45 71 117 79 170 l7 47 1148 2
c1142 3 1149 3 1176 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -34 21
-1176 24 l-1148 2 -7 47 c-8 53 -41 125 -79 170 -14 16 -53 45 -88 64 l-63 34
-225 2 c-124 1 -238 -2 -255 -6z m395 -471 l0 -160 -160 0 -160 0 0 160 0 160
160 0 160 0 0 -160z"
                />
              </g>
            </svg>
          </button>
          <button
            class="tooltip"
            tooltip="Финансы"
            tooltip-position="right"
            @click="$router.push('/finance')"
          >
            <img
              class="financy__svg"
              :src="require('@/assets/financy.svg')"
              alt=""
            />
          </button>
        </div>
        <div class="header__right">
          <a
            class="setting-icon tooltip"
            tooltip="Настройки"
            @click="show_setting = true"
            tooltip-position="left"
          >
            <i class="fa fa-cogs" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <HeaderComment @show_balance_plus="func_show_plus_balance" />
      <transition name="slide_filter">
        <FilterComponent
          v-if="show_filter"
          @close_filter="func_close_filter()"
          @change_filter="func_change_filter"
        />
      </transition>
      <Loader :show="progress" />
      <div class="data_table" v-if="filter_list.length > 0">
        <transition-group
          name="card_list"
          tag="div"
          class="main__orders"
          v-if="
            USER_STATE &&
            USER_STATE.user_id &&
            !USER_STATE.row &&
            filter_list.length > 0
          "
        >
          <Card
            v-for="item in filter_list"
            :key="item.order_id"
            :obj="item"
            @show_detail="func_show_detail(item)"
          />
        </transition-group>
        <CardTable
          :card_list="filter_list"
          v-if="
            USER_STATE &&
            USER_STATE.user_id &&
            USER_STATE.row &&
            filter_list.length > 0
          "
          @get_detail="func_show_detail"
          @show_customer="func_show_customer"
        />
      </div>
      <div class="main__offset" v-show="limit * offset + limit < count_row">
        <div @click="func_show_more()" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <FirstModel :progress="progress" :card_list="filter_list" />

      <transition name="fade_down_detail">
        <Detail
          :order_id="detail_id"
          v-if="detail_id"
          @close_detail="detail_id = null"
          @change_data="func_change_data"
          @show_order="func_show_order"
          @show_user="func_show_customer"
          :update_detail="update_detail"
        />
      </transition>

      <transition name="setting_fade">
        <Setting
          v-if="show_setting"
          @close_setting="show_setting = false"
          @change_row="func_change_class"
          @show_obj="func_show_page"
        />
      </transition>

      <OrderProduct
        v-if="show_order"
        @close_users="func_close_order"
        :product_order="product_order"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FirstModel from "@/components/Main/first_open_model";
import Card from "@/components/Main/card";
import Detail from "@/components/Main/details";
import FilterComponent from "@/components/Main/filter";
import CardTable from "@/components/Main/card_table";
import OrderProduct from "@/components/Main/order_products.vue";
import Setting from "@/components/Main/setting";
import HeaderComment from "@/components/Main/header_comment.vue";
import Loader from "@/components/loader";

export default {
  components: {
    Card,
    Detail,
    FilterComponent,
    Setting,
    HeaderComment,
    CardTable,
    Loader,
    OrderProduct,
    FirstModel,
  },
  data() {
    return {
      card_list: [],
      count_row: 0,
      limit: 500,
      offset: 0,
      detail_id: null,
      show_add_order: false,
      show_filter: false,
      show_setting: false,
      show_setting_user: false,
      show_user: false,
      show_products: false,
      show_order: false,
      filter_obj: {},
      filter: false,
      show_drop: false,
      progress: true,
      interval: null,
      customer_id_show: null,
      update_detail: false,
      product_order: null,
      close_order: true,
    };
  },
  watch: {
    USER_STATE() {
      this.func_upload_script();
    },
    show_filter(val) {
      let user_state = this.USER_STATE;
      if (val) {
        this.close_order = user_state.close_order;
        user_state.close_order = false;
        this.$store.commit("CHANGE_USER", user_state);
      } else {
        user_state.close_order = this.close_order;
        this.$store.commit("CHANGE_USER", user_state);
        this.close_order = null;
      }
    },
  },
  methods: {
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_close_order() {
      this.show_order = false;
      this.update_detail = true;
      setTimeout(() => {
        this.update_detail = false;
      }, 1000);
    },
    func_show_order(order) {
      this.product_order = order;
      this.show_order = true;
    },
    func_show_more() {
      this.offset++;
      this.func_get_data();
    },

    func_get_data() {
      this.progress = true;
      let url = "order.php/get_order";
      let param = {
        limit: this.limit,
        offset: this.offset,
      };

      this.$sendRequest(url, param).then((result) => {
        if (result && result.data) {
          setTimeout(() => {
            result.data.forEach((item) => {
              this.card_list.push(item);
            });
            this.progress = false;
          }, 500);
          this.count_row = Number(result.count_row);
        }
      });
    },

    func_upload_script() {
      if (this.USER_STATE) {
        if (this.USER_STATE.anim) {
          if (!document.querySelector("canvas")) {
            document.querySelector("body").classList.add("trans");
            setTimeout(() => {
              if (window.bubbly) {
                window.bubbly();
              }
            }, 500);
          }
        } else {
          let canvas_all = document.querySelectorAll("canvas");
          document.querySelector("body").classList.remove("trans");
          if (!canvas_all) return;
          canvas_all.forEach((item) => {
            document.querySelector("body").removeChild(item);
          });
        }
      }
    },

    func_close_filter() {
      this.show_filter = false;
      this.filter_obj = {};
    },
    func_change_data(obj) {
      if (obj) {
        let card = this.card_list.find(
          (x) => Number(x.order_id) === Number(obj.order_id)
        );
        if (card) {
          card.status = obj.status ? obj.status.value : "";
          card.status_id = obj.status ? obj.status.id : "";
          card.status_color = obj.status.status_color;
          card.device = obj.device ? obj.device.value : "";
          card.group_name = obj.group_name || "";
          card.fire = obj.fire;
        }
      }
    },
    func_show_detail(item) {
      if (item) {
        this.detail_id = item.order_id;
      }
    },
    func_change_filter(obj) {
      if (obj) {
        if (Number(obj.status_selected) !== 0 || obj.search || obj.date_end) {
          this.filter = true;
          this.filter_obj = obj;
        } else {
          this.filter = false;
          this.filter_obj = {};
        }
      }
    },

    func_change_class(value) {
      let user_setting = this.USER_STATE;
      user_setting.row = value;
      this.$store.commit("CHANGE_USER", user_setting);
    },
    func_show_page(value) {
      if (value === "setting") {
        this.show_add_order = false;
        this.show_setting = false;
        this.show_filter = false;
        this.detail_id = null;

        this.show_setting_user = true;
      }
    },
    func_save_row() {
      let url = "order.php/save_setting_user";
      let param = {
        row: this.USER_STATE.row === true ? 1 : 0,
      };
      this.$sendRequest(url, param);
    },
    order_link(order_id) {
      this.detail_id = order_id;
    },
    func_show_customer(customer_id) {
      this.customer_id_show = customer_id;
      this.show_user = true;
    },
    func_show_message(item) {
      item.showing = "1";
      let url = "order.php/show_message";
      this.$sendRequest(url, item).then((result) => {
        if (!result) return;
        this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
        this.$store.commit(
          "CHANGE_MESSAGE_NO_SHOW",
          Number(result.count_no_show) > 0 ? true : false
        );
      });
    },
    func_drop_message() {
      if (this.MESSAGE_LIST_STATE.length > 0) {
        this.show_drop = !this.show_drop;
        if (this.show_drop) {
          let blur = document.createElement("div");
          blur.classList.add("blur");
          document
            .querySelector("#app")
            .insertBefore(blur, document.querySelector("#app").firstChild);
        } else {
          document
            .querySelector("#app")
            .removeChild(document.querySelector(".blur"));
        }
      }
    },
    func_show_plus_balance() {
      this.show_setting_user = true;
    },
    func_get_message() {
      let url = "order.php/get_message";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
        this.$store.commit(
          "CHANGE_MESSAGE_NO_SHOW",
          Number(result.count_no_show) > 0 ? true : false
        );
      });

      this.interval = setTimeout(this.func_tick, 10000);
    },
    func_tick() {
      if (this.USER_STATE) {
        let url = "order.php/get_message";
        this.$sendRequest(url, {}).then((result) => {
          if (!result) return;
          this.$store.commit("CHANGE_MESSAGE_LIST", result.message_list);
          this.$store.commit(
            "CHANGE_MESSAGE_NO_SHOW",
            Number(result.count_no_show) > 0 ? true : false
          );
          this.interval = setTimeout(this.func_tick, 10000);
        });
      }
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.link_order_id) {
      this.order_link(this.$route.params.link_order_id);
    }
    this.func_get_data();
    this.func_upload_script();
    //this.func_get_message();
  },
  computed: {
    ...mapGetters(["MESSAGE_NO_SHOW_STATE"]),
    ...mapGetters(["MESSAGE_LIST_STATE"]),
    ...mapGetters(["USER_STATE"]),
    user_row: {
      get() {
        return this.USER_STATE.row;
      },
      set(value) {
        let user_setting = this.USER_STATE;
        user_setting.row = value;
        this.$store.commit("CHANGE_USER", user_setting);
      },
    },
    filter_list() {
      let status = !(
        !this.filter_obj.status_selected ||
        Number(this.filter_obj.status_selected) === 0
      );
      let search = !(!this.filter_obj.search || this.filter_obj.search === "");
      let date_end = !(
        !this.filter_obj.date_end || this.filter_obj.date_end === ""
      );

      let new_list = this.card_list
        .filter((x) => {
          if (status && !search && !date_end) {
            if (
              Number(x.status_id) === Number(this.filter_obj.status_selected)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (status && search && !date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              Number(x.status_id) === Number(this.filter_obj.status_selected)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (status && search && date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              Number(x.status_id) === Number(this.filter_obj.status_selected) &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && search && date_end) {
            if (
              x.phone.indexOf(this.filter_obj.search) !== -1 &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && !search && date_end) {
            if (
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
              new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else if (!status && search && !date_end) {
            if (x.phone.indexOf(this.filter_obj.search) !== -1) {
              return true;
            } else {
              return false;
            }
          } else if (status && !search && date_end) {
            if (
              Number(x.status_id) === Number(this.filter_obj.status_selected) &&
              new Date(
                this.iosDate(this.filter_obj.date_end)
              ).toLocaleDateString() ===
                new Date(this.iosDate(x.date_create)).toLocaleDateString()
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          return (
            (new Date(b.date_create) > new Date(a.date_create)) -
            (new Date(a.date_create) > new Date(b.date_create))
          );
        });

      if (this.$store.state.user_setting.close_order) {
        return new_list.filter((x) => {
          if (x.status !== "Завершен") {
            if (x.status !== "Завершен неудачно") {
              return true;
            }
            return false;
          } else {
            return false;
          }
        });
      } else {
        return new_list;
      }
    },
  },
};
</script>

<style>
.slide_filter-enter {
  transform: translateX(-100%);
}

.slide_filter-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_filter-enter-to {
  transform: translateX(0);
}
.slide_filter-leave {
  transform: translateX(0);
}
.slide_filter-leave-active {
  transition: 0.5s;
  will-change: transform;
}

.slide_filter-leave-to {
  transform: translateX(-100%);
}

.slide_filter_hide-enter-active,
.slide_filter_hide-leave-active {
  transition: 0.5s;
  will-change: transform;
}
.slide_filter_hide-enter {
  transform: translateX(0px);
}
.slide_filter_hide-leave-to {
  transform: translateX(-100%);
}

.card_list-enter-active,
.card_list-leave-active {
  transition: 0.7s;
}
.card_list-enter,
.card_list-leave-to {
  opacity: 0;
}

.fade_down_detail-enter-active,
.fade_down_detail-leave-active {
  transition: 0.5s;
}
.fade_down_detail-enter {
  transform: translateY(100%) scale(0);
}
.fade_down_detail-enter-to {
  transform: translateY(0px) scale(1);
}
.fade_down_detail-leave-to {
  transform: translateY(100%) scale(0);
}

.fade_down-enter-active,
.fade_down-leave-active {
  transition: 0.5s;
  transform: translateY(0px);
}
.fade_down-enter, .fade_down-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(100%);
}

.fade_up-enter {
  transform: translate(-100%, 100%) scale(0);
}

.fade_up-enter-active {
  transition: transform 0.5s;
}

.fade_up-enter-to {
  transform: translate(0%, 0%) scale(1);
}

.fade_up-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up-leave-active {
  transition: transform 0.5s;
  transform: translateY(0px);
}

.fade_up-leave-to {
  transform: translateY(100%);
}

.setting_fade-enter {
  transform: translateX(100%);
}

.setting_fade-enter-active {
  transition: 0.5s;
}

.setting_fade-enter-to {
  transform: translateX(0px);
}

.setting_fade-leave {
  transform: translateX(0px);
}
.setting_fade-leave-active {
  transition: transform 0.3s;
}

.setting_fade-leave-to {
  transform: translateX(100%);
}

.message_drop-enter {
  transform: translate(-25%, -25%) scaleX(0.2);
  transform-origin: top left;
}

.message_drop-enter-active {
  transition: 0.5s;
  transform-origin: top left;
}

.message_drop-enter-to {
  transform: translate(0, 0) scale(1);
  transform-origin: top left;
}

.message_drop-leave {
  transform: translate(0, 0) scaleX(1);
  transform-origin: top left;
}
.message_drop-leave-active {
  transition: transform 0.3s;
  transform-origin: top left;
}

.message_drop-leave-to {
  transform: translate(-10%, -10%) scale(0);
  transform-origin: top left;
}
</style>
