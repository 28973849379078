<template>
  <div
    class="modal__pay__popup"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="modal__pay__wrapper">
      <div class="header__back_btn">
        <div @click="$emit('close_modal')" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2>Пополнить счет</h2>
      </div>
      <div class="pay__content">
        <div class="balance__info">
          <div class="info__item">
            <span>На 1 месяц</span>
            <button @click="func_pay(Number(tarif) * 30)">Оплатить</button>
          </div>
          <div class="info__item">
            <span>На 3 месяца</span>
            <button @click="func_pay(Number(tarif) * 90)">Оплатить</button>
          </div>
        </div>
        <div class="proizvol__pay">
          <p>Минимальный платеж 100 рублей</p>
          <div class="proizvol__form">
            <input
              type="number"
              required
              min="100"
              max="9999999"
              v-model="prozivol_summ"
            />
            <button @click="func_pay(prozivol_summ)">Оплатить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tarif: String,
  },
  data() {
    return {
      prozivol_summ: 100,
    };
  },
  methods: {
    func_pay(summ) {
      if (isNaN(Number(summ))) return;
      if (Number(summ) < 100) return;

      let url = "order.php/payment";
      this.$sendRequest(url, {
        amount: summ,
      }).then((result) => {
        if (!result) return;
        if (!result.link) return;
        window.open(result.link, "_blank");
      });
    },
  },
};
</script>

<style></style>
