<template>
  <div>
    <div class="step2">
      <div class="step__header">
        <h2>Продолжим?</h2>
        <h3>Заполните немного информации о вас</h3>
      </div>
      <div class="step__form">
        <form @submit.prevent="func_save_info_user">
          <div
            class="registry__form_input"
            :class="{ error_input: errors.includes(1) }"
          >
            <input
              type="text"
              required
              placeholder="Название компании"
              v-model="company"
            />
          </div>
          <div
            class="registry__form_input"
            required
            :class="{ error_input: errors.includes(2) }"
          >
            <input type="text" placeholder="Город" v-model="city" />
          </div>
          <div
            class="registry__form_input"
            required
            :class="{ error_input: errors.includes(3) }"
          >
            <input type="text" placeholder="Ваше имя" v-model="username" />
          </div>
          <button>Далее >></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    company: "",
    city: "",
    username: "",
    errors: [],
  }),
  methods: {
    func_save_info_user() {
      this.errors = [];
      if (!this.company) this.errors.push(1);
      if (!this.city) this.errors.push(2);
      if (!this.username) this.errors.push(3);

      if (this.errors.length > 0) return;

      this.$sendRequest("order.php/save_profile_registry", {
        company: this.company,
        city: this.city,
        username: this.username,
      }).then((result) => {
        if (result && result.success) this.$emit("change_step", 3);
        else this.errors = [1, 2, 3];
      });
    },
    func_get_profile() {
      this.$sendRequest("order.php/get_profile_registry", {}).then((result) => {
        if (!result) return;
        this.company = result.company;
        this.city = result.city;
        this.username = result.username;
      });
    },
  },
  mounted() {
    this.func_get_profile();
  },
};
</script>

<style></style>
