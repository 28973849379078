import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import store from './store.js';
import router from './router/router';
import iosAlertView from 'vue-ios-alertview';
import VueHtmlToPaper from 'vue-html-to-paper';
import tinymce from 'vue-tinymce-editor';
import request from '@/plugins/request.js';
import VueApexCharts from 'vue-apexcharts'
import vuetify from '@/plugins/vuetify' // path to vuetify export

Vue.component('tinymce', tinymce)
Vue.config.productionTip = false
Vue.use(iosAlertView);
Vue.use(VueHtmlToPaper);
Vue.use(request);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.prototype.$http = axios;

let key = localStorage.getItem('key');
if (key) {
  request.sendRequest('order.php/get_param_user', {}).then(result => {
    result.row = result.row === "true" ? true : false;
    result.anim = result.anim === "1" ? true : false;
    result.blur = result.blur === "1" ? true : false;
    result.close_order = result.close_order === "1" ? true : false;
    result.start_show = result.start_show === "1" ? true : false;
    store.commit("CHANGE_USER", result);
    if (result.fields) {
      store.commit("CHANGE_FIELDS", result.fields);
    }
    new Vue({
      render: h => h(App),
      store,
      router
    }).$mount('#app')
  });
} else {
  new Vue({
    render: h => h(App),
    store,
    router,
    vuetify
  }).$mount('#app')
}

