<template>
  <div class="products__order__wrapper">
    <div
      class="close__products tooltip"
      @click="$emit('close_users')"
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>

    <div class="product__order__content">
      <div class="group__block">
        <div class="header">
          <h4>Номер заказа</h4>
          <h3>{{ product_order.order_num }}</h3>
        </div>
        <div class="group__list">
          <div class="group__body" v-if="group_list.length > 0">
            <div
              class="group__item"
              v-for="item in group_list"
              :key="item.id"
              :class="{
                selected: selected_group && selected_group.id === item.id,
              }"
              @click="func_select_group_item(item)"
            >
              <div class="title">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="itog">
          <div class="itog__wrapper">Итого: {{ func_get_summ() }} руб</div>
        </div>
      </div>
      <div class="table__block">
        <div class="search__block">
          <div class="header__products">
            <input
              type="text"
              placeholder="Поиск по названию"
              v-model="search.search_name"
            />
            <div class="buttons">
              <button
                class="btn__search tooltip"
                tooltip="Фильтр"
                tooltip-position="bottom right"
                @click="search.show = !search.show"
              >
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>

            <input
              type="text"
              placeholder="Поиск по артикулу"
              v-model="search.search_article"
            />
          </div>
        </div>
        <div class="table__main">
          <table>
            <thead>
              <tr>
                <th>Артикул</th>
                <th>Товар</th>
                <th>Cтоимость</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="filter_list.length > 0">
              <tr v-for="item in filter_list" :key="item.product_id">
                <td>
                  {{ item.article }}
                </td>
                <td>
                  {{ item.product }}
                </td>
                <td>
                  {{ item.price }}
                </td>
                <td>
                  <button @click="func_add_order(item)">
                    <i aria-hidden="true" class="fa fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table__add">
          <table>
            <thead>
              <tr>
                <th>Артикул</th>
                <th>Товар</th>
                <th>Cтоимость</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="order_list.length > 0">
              <tr v-for="(item, index) in order_list" :key="index">
                <td>
                  {{ item.article }}
                </td>
                <td>
                  {{ item.product }}
                </td>
                <td>
                  {{ item.price }}
                </td>
                <td>
                  <button @click="func_delete_order(item)">
                    <i aria-hidden="true" class="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product_order: Object,
  },
  data() {
    return {
      search: {
        show: false,
        search_name: "",
        search_article: "",
      },
      show_add_product: false,
      group: "",
      products_list: [],
      order_list: [],
      group_list: [],
      selected_group: null,
    };
  },
  methods: {
    func_delete_order(obj) {
      let url = "order.php/remove_order_product";
      this.$sendRequest(url, {
        ...obj,
        order_id: this.product_order.order_id,
      }).then(() => {
        this.order_list.splice(
          this.order_list.findIndex(
            (x) => x.order_product_id === obj.order_product_id
          ),
          1
        );
      });
    },
    func_get_summ() {
      let summ = 0;
      this.order_list.forEach(function (item) {
        summ += Number(item.price);
      });
      return Number(summ).toFixed(2);
    },
    func_add_order(obj) {
      // let isCheck = false;
      // this.order_list.forEach(function (item) {
      //   if (item.product_id === obj.product_id) {
      //     isCheck = true;
      //   }
      // });

      // if (!isCheck) {
      this.func_add_order_db(obj);
      //}
    },
    func_add_order_db(obj) {
      if (!obj) return;
      let url = "order.php/add_order_product";
      this.$sendRequest(url, {
        ...obj,
        order_id: this.product_order.order_id,
      }).then((result) => {
        if (!result) return;
        this.order_list.unshift(result);
      });
    },
    func_get_data() {
      if (!this.selected_group) return;
      let url = "order.php/get_products";
      this.$sendRequest(url, {
        group_id: this.selected_group.id,
      }).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.products_list = result;
      });
    },
    edit_product(obj) {
      if (obj.readonly) {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.products_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_product";
        this.$sendRequest(url, obj);
      }
    },
    func_edit_group(obj) {
      if (obj.readonly) {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        obj.readonly = false;
      } else {
        this.group_list.forEach(function (item) {
          item.readonly = true;
        });
        let url = "order.php/edit_group_product";
        this.$sendRequest(url, obj);
      }
    },
    func_save_group() {
      let url = "order.php/add_group_product";
      this.$sendRequest(url, { value: this.group }).then((result) => {
        if (!result) return;
        result.readonly = result.readonly === "true" ? true : false;
        this.group_list.unshift(result);
        this.group = "";
      });
    },
    func_get_groups() {
      let url = "order.php/get_groups_product";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        result.forEach(function (item) {
          item.readonly = item.readonly === "true" ? true : false;
        });
        this.group_list = result;
      });
    },
    func_select_group_item(obj) {
      this.selected_group = obj;
      this.func_get_data();
    },
    func_remove_group(item) {
      if (!item) return;

      if (!item.readonly) {
        item.readonly = true;
        return;
      }

      let url = "order.php/remove_group_product";
      this.$sendRequest(url, { id: item.id }).then(() => {
        this.group_list.splice(
          this.group_list.findIndex((x) => x.id === item.id),
          1
        );
        this.selected_group = null;
      });
    },
    func_add_product(obj) {
      if (!obj) return;
      this.products_list.unshift(obj);
      this.show_add_product = false;
    },
    remove_product(obj) {
      if (!obj) return;

      if (!obj.readonly) {
        obj.readonly = true;
        return;
      }
      let url = "order.php/remove_product";
      this.$sendRequest(url, { product_id: obj.product_id }).then(() => {
        this.products_list.splice(
          this.products_list.findIndex((x) => x.product_id === obj.product_id),
          1
        );
      });
    },
    func_get_order_product() {
      let url = "order.php/get_products_detail";
      this.$sendRequest(url, {
        order_id: this.product_order.order_id,
      }).then((result) => {
        if (!result) return;
        this.order_list = result;
      });
    },
  },
  mounted() {
    this.func_get_groups();
    this.func_get_order_product();
  },
  computed: {
    filter_list() {
      let search_name = !!this.search.search_name;
      let search_article = !!this.search.search_article;

      return [...this.products_list].filter((x) => {
        if (search_name && search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1 ||
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (search_name && !search_article) {
          if (
            x.product
              .toLowerCase()
              .indexOf(this.search.search_name.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (!search_name && search_article) {
          if (
            x.article
              .toLowerCase()
              .indexOf(this.search.search_article.toLowerCase()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      // .filter(
      //   (o) => !this.order_list.some((i) => i.product_id === o.product_id)
      // );
    },
  },
};
</script>

<style></style>
