<template>
  <div class="detail__modal__wrapper">
    <div class="detail_message_box" v-if="option.value === 'Завершен'">
      <div class="payment__box" v-if="data && data.products.length > 0">
        <div class="products__list">
          <div
            class="product__item"
            v-for="(item, index) in data.products"
            :key="index"
          >
            <div class="product__title">{{ item.product }}</div>
            <div class="product__price">{{ item.price || 0 }} руб</div>
          </div>
        </div>
        <div class="payment">
          <div class="payment__item">
            <h3 :style="{ color: error === true ? 'rgb(255, 117, 117)' : '' }">
              Способ оплаты:
            </h3>
            <div class="buttons">
              <button
                v-for="item in buttons"
                :key="item.id"
                :style="{ color: item.selected ? 'rgb(41, 229, 165)' : '' }"
                @click="func_selected_money(item)"
              >
                <i :class="item.icon"></i>
              </button>
            </div>
          </div>
          <div class="payment__item payment__item_no">
            <div class="price">
              <p>Итого:</p>
              <p>{{ itog }} руб</p>
            </div>
            <div class="price prepayment" v-if="prepayment">
              <p>Предоплата:</p>
              <p>{{ Number(data.prepayment).toFixed(2) }} руб</p>
            </div>
          </div>
        </div>

        <p class="p__text">
          После завершения заказа, его редактирование будет недоступно
        </p>
        <div class="buttons__close">
          <button @click="$emit('cancel', option)">Отмена</button>
          <button @click="func_save">Завершить</button>
        </div>
      </div>
      <div
        class="payment__box"
        v-if="data && data.products.length === 0 && !prepayment"
      >
        <div class="products__list">
      
          <div class="form">
            
            <div class="form__group">
              <label>Добавить описание:</label>
              <input type="text" v-model="data.comment" />
            </div>
          </div>
        </div>
        

        <p class="p__text">
          После завершения заказа, его редактирование будет недоступно
        </p>
        <div class="buttons__close">
          <button @click="$emit('cancel', option)">Отмена</button>
          <button @click="func_save">Завершить</button>
        </div>
      </div>
      <div
        class="payment__box"
        v-if="data && data.products.length === 0 && prepayment"
      >
        <div class="products__list" style="height: 200px">
          <p>В заказе нет товаров, но есть предоплата.</p>
          <p>Отредактируете заказ в этом окне перед завершением.</p>
          <p>
            Если оставить только предоплату, она не будет учтена как прибыль.
          </p>
          <div class="form">
            <div class="form__group">
              <label>Предоплата:</label>
              <input
                class="input__number"
                type="number"
                v-model="data.prepayment"
              />
            </div>
            <div class="form__group">
              <label>Итоговая сумма:</label>
              <input class="input__number" type="number" v-model="itog" />
            </div>
            <div class="form__group">
              <label>Добавить описание:</label>
              <input type="text" v-model="data.comment" />
            </div>
          </div>
        </div>
        <div class="payment">
          <div class="payment__item">
            <h3 :style="{ color: error === true ? 'rgb(255, 117, 117)' : '' }">
              Способ оплаты:
            </h3>
            <div class="buttons">
              <button
                v-for="item in buttons"
                :key="item.id"
                :style="{ color: item.selected ? 'rgb(41, 229, 165)' : '' }"
                @click="func_selected_money(item)"
              >
                <i :class="item.icon"></i>
              </button>
            </div>
          </div>
          <div class="payment__item payment__item_no">
            <div class="price">
              <p>Итого:</p>
              <p>{{ itog }} руб</p>
            </div>
            <div class="price prepayment" v-if="prepayment">
              <p>Предоплата:</p>
              <p>{{ Number(data.prepayment).toFixed(2) }} руб</p>
            </div>
          </div>
        </div>

        <p class="p__text">
          После завершения заказа, его редактирование будет недоступно
        </p>
        <div class="buttons__close">
          <button @click="$emit('cancel', option)">Отмена</button>
          <button @click="func_save">Завершить</button>
        </div>
      </div>
    </div>
    <div class="detail_message_box order__error" v-else>
      <h4>Заказ завершен неудачно</h4>
      <h4>Сумма прибыли не будет учтена в отчетах</h4>
      <p class="p__text">
        После завершения заказа, его редактирование будет недоступно
      </p>
      <div class="buttons__close">
        <button @click="$emit('cancel', option)">Отмена</button>
        <button @click="func_save">Завершить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      error: false,
      prepayment: false,
      buttons: [
        { id: 1, icon: "fa fa-credit-card-alt", selected: true },
        { id: 2, icon: "fa fa-money", selected: false },
      ],
      data: null,
    };
  },
  methods: {
    func_selected_money(obj) {
      this.buttons.forEach(function (item) {
        item.selected = false;
      });
      obj.selected = true;
    },
    func_get_summ() {
      let summ = 0;
      if (this.data.products) {
        this.data.products.forEach(function (item) {
          summ += Number(item.price || 0);
        });
      }
      return Number(summ).toFixed(2);
    },
    func_save() {
      if (this.option.value === "Завершен неудачно") {
        this.$emit("save", this.option);
      } else {
        let type_amount = this.buttons.find((x) => x.selected);
        if (type_amount) {
          let option_new = {
            ...this.option,
            price: this.data.price,
            prepayment: this.data.prepayment,
            comment: this.data.comment,
            itog: this.itog,
          };
          option_new.type_amount = type_amount.id;
          this.error = false;
          this.$emit("save", option_new);
        } else {
          this.error = true;
        }
      }
    },
    fun_get_data() {
      let order_id = this.option.item
        ? this.option.item.order_id
        : this.option.order_id;
      this.$sendRequest("order.php/get_close_order_detail", {
        order_id,
      }).then((result) => {
        if (!result) return;
        this.data = result;
        this.prepayment = Number(result.prepayment || 0) > 0 ? true : false;
      });
    },
  },
  mounted() {
    this.fun_get_data();
  },
  computed: {
    itog: {
      get() {
        let summ = 0;
        if (this.data && this.data.products.length > 0) {
          this.data.products.forEach(function (item) {
            summ += Number(item.price || 0);
          });
        } else {
          summ = this.data && this.data.price ? this.data.price : 0;
        }
        return summ;
      },
      set(val) {
        this.data.price = val;
      },
    },
  },
};
</script>

<style></style>
