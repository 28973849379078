<template>
  <div id="app">
    <transition :name="transition_name" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      transition_name: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "clients" || from.name === "clients") {
        this.transition_name = "customers";
      } else if (to.name === "products" || from.name === "products") {
        this.transition_name = "customers";
      } else if (to.name === "setting" || from.name === "setting") {
        this.transition_name = "customers";
      } else if (to.name === "finance" || from.name === "finance") {
        this.transition_name = "customers";
      } else {
        this.transition_name = "component-fade";
      }
    },
  },
};
</script>

<style>
@import url("css/style.css");
@import url("css/art.css");
@import url("css/registry.css");
@import url("css/details.css");
@import url("css/media.css");
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.5s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.customers-enter {
  transform: translateY(-150%) scale(0.2);
}

.customers-enter-active {
  transition: 0.5s;
}

.customers-enter-to {
  transform: translateY(0px) scale(1);
}

.customers-leave {
  transform: translateY(0px) scale(1);
}
.customers-leave-active {
  transition: transform 0.3s;
}

.customers-leave-to {
  transform: translateY(-150%) scale(0.2);
}
</style>
