<template>
  <div class="step5">
    <div class="step__header">
      <h2>Готово</h2>
      <img src="@/assets/ctcv.png" alt="" />
      <h3>Любые изменения вы сможете производить в настройках</h3>
    </div>
    <button @click="$router.push('/')">Завершить</button>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
