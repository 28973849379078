import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js';

import Auth from '../components/_Pages/auth'
import Registry from '../components/_Pages/registry'
import Resetpass from '../components/_Pages/resetpass'
import Main from '../components/_Pages/main'
import Pay from '../components/_Pages/pay'
import DaysNo from '../components/_Pages/days_no.vue'
import Clients from '../components/_Pages/clients.vue'
import Products from '../components/_Pages/products.vue'
import Setting from '../components/_Pages/setting_user.vue'
import Finance from '../components/_Pages/finance.vue'


Vue.use(Router);

function auth_result(next) {
  if (localStorage.getItem('key') && store.state.user_setting) {
    if (store.state.user_setting.count_day === 0) {
      next({ name: 'days_no' });
    } else {
      next();
    }
  } else {
    next({ name: 'auth' });
  }
}
function no_auth_result(next) {
  if (localStorage.getItem('key') && store.state.user_setting) {
    next({ name: 'main' });
  } else {
    next();
  }
}

function days_no_result(next) {
  if (localStorage.getItem('key') && store.state.user_setting) {
    if (store.state.user_setting.count_day > 0) {
      next({ name: 'main' });
    } else {
      next();
    }
  } else {
    next({ name: 'auth' });
  }
}

let router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'main',
    component: Main,
    beforeEnter: (to, from, next) => auth_result(next)
  }, {
    path: '/auth',
    name: 'auth',
    component: Auth,
    beforeEnter: (to, from, next) => no_auth_result(next)
  }, {
    path: '/registry',
    name: 'registry',
    component: Registry,
    beforeEnter: (to, from, next) => no_auth_result(next)
  },
  {
    path: '/resetpass',
    name: 'resetpass',
    component: Resetpass,
    beforeEnter: (to, from, next) => no_auth_result(next)
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay,
    props: true,
    beforeEnter: (to, from, next) => auth_result(next)
  }, {
    path: '/days_no',
    name: 'days_no',
    component: DaysNo,
    beforeEnter: (to, from, next) => days_no_result(next)
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    beforeEnter: (to, from, next) => auth_result(next),
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
    beforeEnter: (to, from, next) => auth_result(next),
  }, {
    path: '/setting',
    name: 'setting',
    component: Setting,
    props: true,
    beforeEnter: (to, from, next) => auth_result(next),
  }, {
    path: '/finance',
    name: 'finance',
    component: Finance,
    beforeEnter: (to, from, next) => auth_result(next),
  },
  {
    path: '*',
    component: localStorage.getItem('key') ? Main : Auth
  },]
})

export default router;